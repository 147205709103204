export const RouterParams = {
  FeedId: ':id',
  ClientId: ':clientId'
};

export const RouterPaths = {
  Landing: '/home',
  PriceFeeds: '/price-feeds',
  RiskFeeds: '/risk-feeds',
  PriceFeedDetails: `/price-feeds/${RouterParams.FeedId}`,
  RiskFeedDetails: `/risk-feeds/${RouterParams.ClientId}`
};
