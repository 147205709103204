export enum ApiEndpoint {
  Feeds = '/trading-view/feeds',
  FeedsData = '/trading-view/feeds-data',
  Validators = '/trading-view/validators',
  Providers = '/trading-view/reports',
  RiskOracleOverview = '/risk-oracle/api/v1/overview',
  RiskOracleProtocolOverview = '/risk-oracle/api/v1/protocol_overview',
  RiskOracleProtocolRecommendations = '/risk-oracle/api/v1/protocol_recommendations'
}

export enum WsEndpoint {
  Feeds = 'stream-feeds',
  Validators = 'stream-validators',
  Providers = 'stream-reports'
}

export type FeedPrice = {
  interval: '1h' | '24h' | '7d';
  diff: number;
};

export type Feed = {
  feed: string;
  type: 'pull' | 'push';
  providers: string[];
  last_price: number;
  market_cap: number;
  circulating_supply: number;
  usd_24h_vol: number;
  prices: FeedPrice[];
  network?: string;
  address?: string;
  deviation_threshold?: number;
  nextTimestamp?: number;
};

export type FeedsResponse = {
  feeds: Feed[];
};

export type Granularity = '1' | '1h' | '24h';
export type TimeRange = '1h' | '24h' | '7d' | '30d';
export type Price = {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  avg: number;
};

export type FeedsDataResponse = {
  queryType: Granularity;
  interval: TimeRange;
  result: Price[];
  min: number;
  max: number;
  average: number;
  diff: number;
  last_price: number;
};

export type FeedsDataParams = {
  interval: TimeRange;
  type: Granularity;
  feed: string;
};

export type RiskOracleProtocolParams = {
  protocol: string;
};

export type FeedParam = {
  feed: string;
};

export type FeedsParam = {
  feeds: string;
};

export type Validator = {
  name: string;
  price: number;
  timestamp: number;
  failed_rounds: number;
  success_rate: number;
};

export type ValidatorsResponse = {
  validators: { validators: Validator[] }[];
};

export type Provider = {
  failed_counts: number;
  feedId: string;
  price: number;
  provider: string;
  success_rate: number;
  ts: number;
};

export type ProvidersResponse = {
  reports: Provider[];
};

export type RiskOracleOverviewResponse = {
  total_value_secured: number;
  total_assets_secured: number;
  total_blockchains: number;
  risk_oracle_uptime: number;
  total_risk_oracle_updates: number;
  supported_protocols: string[];
  total_risk_parameters: number;
};

export type RiskOracleProtocolOverviewResponse = {
  total_value_secured: number;
  total_assets_secured: number;
  total_blockchains: number;
  risk_oracle_uptime: number;
};

export type RiskOracleProtocolRecommendation = {
  risk_parameter_name: string;
  market: {
    chain: string;
    market: string;
    pool_name?: string;
  };
  asset: string;
  recommended_value: number;
  recommended_value_type: string;
  tx_hash: string;
  last_on_chain_update: number;
  historical_chart: {
    timestamp: number;
    value: number;
    tx_hash: string;
  }[];
};

export type RiskOracleProtocolRecommendationResponse = RiskOracleProtocolRecommendation[];

export type ApiResponseMap = {
  [ApiEndpoint.Feeds]: FeedsResponse;
  [ApiEndpoint.FeedsData]: FeedsDataResponse;
  [ApiEndpoint.Validators]: ValidatorsResponse;
  [ApiEndpoint.Providers]: ProvidersResponse;
  [ApiEndpoint.RiskOracleOverview]: RiskOracleOverviewResponse;
  [ApiEndpoint.RiskOracleProtocolOverview]: RiskOracleProtocolOverviewResponse;
  [ApiEndpoint.RiskOracleProtocolRecommendations]: RiskOracleProtocolRecommendationResponse;
};

export type WsDataMap = {
  [WsEndpoint.Feeds]: Feed[];
  [WsEndpoint.Validators]: { validators: Validator[] };
  [WsEndpoint.Providers]: Provider;
};

export type WsParamsMap = {
  [WsEndpoint.Feeds]: FeedParam;
  [WsEndpoint.Validators]: FeedsParam;
  [WsEndpoint.Providers]: FeedParam;
};

export type ApiParamsMap = {
  [ApiEndpoint.Feeds]: FeedParam;
  [ApiEndpoint.FeedsData]: FeedsDataParams;
  [ApiEndpoint.Validators]: FeedsParam;
  [ApiEndpoint.Providers]: FeedParam;
  [ApiEndpoint.RiskOracleOverview]: undefined;
  [ApiEndpoint.RiskOracleProtocolOverview]: RiskOracleProtocolParams;
  [ApiEndpoint.RiskOracleProtocolRecommendations]: RiskOracleProtocolParams;
};

export type ApiResponse<T extends ApiEndpoint> = ApiResponseMap[T];
export type ApiParams<T extends ApiEndpoint> = ApiParamsMap[T];
export type WsData<T extends WsEndpoint> = WsDataMap[T];
export type WsParams<T extends WsEndpoint> = WsParamsMap[T];
