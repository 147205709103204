import { Box, Typography } from '@mui/material';
import Grid from '@mui/system/Grid';
import Wrapper from '@components/wrapper';
import InfoCard from '@components/info-card';
import Clients from '@components/clients';
import { ApiEndpoint, useApiRequest } from '../../api';
import { formatAmount } from '@utils/formatters';

const RiskFeeds = () => {
  const { response, isLoading } = useApiRequest(ApiEndpoint.RiskOracleOverview);

  const infoCards = [
    { title: 'Number of Assets Secured', value: response?.total_assets_secured },
    { title: 'Number of Blockchains', value: response?.total_blockchains },
    {
      title: 'Total Value Secured',
      value: formatAmount(response?.total_value_secured ?? 0, {
        isCurrency: true,
        notation: 'standard'
      })
    },
    { title: 'Number of Risk Oracle Updates', value: response?.total_risk_oracle_updates },
    { title: 'Number of Supported Protocols', value: response?.supported_protocols?.length },
    { title: 'Number of Risk Parameters Monitored', value: response?.total_risk_parameters }
  ];

  return (
    <Wrapper>
      <Box px={3} pt={5} pb={3}>
        <Typography variant="h1">Risk Oracle Overview</Typography>
      </Box>
      <Grid container sx={{ px: { xs: 1, lg: 0 } }}>
        {infoCards.map((card, index) => (
          <Grid size={{ xs: 6, lg: 4 }} py={2} sx={{ px: { xs: 2, lg: 3 } }} key={index}>
            <InfoCard title={card.title} isLoading={isLoading}>
              {card.value}
            </InfoCard>
          </Grid>
        ))}
      </Grid>
      <Clients supportedProtocols={response?.supported_protocols} hideSearch loading={isLoading} />
    </Wrapper>
  );
};

export default RiskFeeds;
