import { Box, Typography, useTheme } from '@mui/material';
import { formatAmount } from '../../utils/formatters';
import Icon from '../icon';

interface PriceChangeProps {
  value: number;
  changeUp?: boolean;
}

const PriceChange = ({ value, changeUp = value >= 0 }: PriceChangeProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box display="flex">
      <Icon
        icon={changeUp ? 'arrow-up' : 'arrow-down'}
        style={{
          fill: changeUp ? theme.palette.success.main : theme.palette.error.main,
          opacity: value !== 0 ? 1 : 0
        }}
      />

      <Typography
        sx={{ color: value !== 0 ? (changeUp ? 'success.main' : 'error.main') : 'greys.400' }}>
        {value
          ? formatAmount(Math.abs(value / 100), { isPercent: true, maximumFractionDigits: 3 })
          : '0.00%'}
      </Typography>
    </Box>
  );
};

export default PriceChange;
