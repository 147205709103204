import React from 'react';
import { Typography, Box, TableCell, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import Wrapper from '@components/wrapper';
import Table, { Column } from '@components/table';
import Icon from '@components/icon';
import PriceChange from '@components/price-change';
import { useApiRequest, ApiEndpoint, FeedPrice, WsEndpoint } from '../../api';
import { formatAmount } from '../../utils/formatters';
import { useFeedsWebSocket } from '../../api/use-web-socket';
import CryptoIcon from '../../components/crypto-icon';
import { RouterParams, RouterPaths } from '../../types/routers';
import { Heartbeat } from '@components/heartbeat';

type Row = {
  id: string;
  price_feed: string;
  current_price: number;
  providers: string[];
  trend1h: number;
  trend24h: number;
  trend7d: number;
  network?: string;
  deviation_threshold?: number;
  nextTimestamp?: number;
};

const PriceFeedsList = (): JSX.Element => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [feedType, setFeedType] = React.useState<'pull' | 'push'>(state?.feedType ?? 'pull');
  const { response, isLoading } = useApiRequest(ApiEndpoint.Feeds);
  const data = useFeedsWebSocket(WsEndpoint.Feeds);

  const tableRows: Row[] = (data || response?.feeds || [])
    .filter((f) => f.type === feedType || !f.type)
    .map((f) => {
      const priceByInterval = groupBy<FeedPrice>(f.prices, 'interval');

      return {
        id: f.feed.replace('/', '-'),
        price_feed: f.feed.split('/').join(' / '),
        current_price: f.last_price,
        providers: f.providers,
        network: f.network,
        trend1h: priceByInterval['1h']?.[0]?.diff || 0,
        trend24h: priceByInterval['24h']?.[0]?.diff || 0,
        trend7d: priceByInterval['7d']?.[0]?.diff || 0,
        deviation_threshold: f.deviation_threshold,
        nextTimestamp: f.nextTimestamp
      };
    });

  const handleFeedTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    selectedFeedType: 'pull' | 'push' | null
  ) => {
    if (selectedFeedType !== null) {
      setFeedType(selectedFeedType);
    }
  };

  const columns: Column<Row>[] = [
    {
      sortBy: 'price_feed',
      label: 'Price Feed'
    },
    {
      label: 'Current Price'
    },
    ...((feedType === 'push'
      ? [
          {
            label: 'Networks'
          },
          {
            label: 'Deviation threshold'
          },
          {
            label: 'Heartbeat'
          }
        ]
      : [
          {
            label: '1H Trend'
          }
        ]) as Column<Row>[]),
    {
      label: '24H Trend'
    },

    {
      label: '7D Trend'
    },
    {
      width: 56
    }
  ];

  return (
    <Wrapper>
      <Box px={3} pt={5} pb={3}>
        <Typography variant="h1">Price Feeds Overview</Typography>
      </Box>
      <Table<Row>
        columns={columns}
        rows={tableRows.sort((a, b) => b.current_price - a.current_price)}
        rowKey="id"
        title={
          <ToggleButtonGroup
            color="primary"
            value={feedType}
            exclusive
            onChange={handleFeedTypeChange}>
            <ToggleButton value="pull">Pull Feeds</ToggleButton>
            <ToggleButton value="push">Push Feeds</ToggleButton>
          </ToggleButtonGroup>
        }
        rowsPerPage={10}
        loading={isLoading}
        rowContent={(rowData: Row) => (
          <React.Fragment>
            <TableCell>
              <Box display="flex" gap={1} alignItems="center">
                <CryptoIcon icon={rowData.id.split('-')[0]} />
                <Typography display="inline-block" whiteSpace="nowrap">
                  {rowData.price_feed}
                </Typography>
              </Box>
            </TableCell>
            <TableCell>
              {formatAmount(rowData.current_price, { isCurrency: true, notation: 'standard' })}
            </TableCell>
            {feedType === 'push' ? (
              <>
                <TableCell>
                  {rowData.network && <CryptoIcon icon={rowData.network.toLowerCase()} />}
                </TableCell>
                <TableCell>
                  {rowData.deviation_threshold !== undefined
                    ? formatAmount(rowData.deviation_threshold / 100, {
                        isPercent: true,
                        notation: 'standard'
                      })
                    : '-'}
                </TableCell>
                <TableCell>
                  <Heartbeat nextTimestamp={rowData.nextTimestamp} />
                </TableCell>
              </>
            ) : (
              <TableCell>
                <PriceChange value={rowData.trend1h} />
              </TableCell>
            )}
            <TableCell>
              <PriceChange value={rowData.trend24h} />
            </TableCell>
            <TableCell>
              <PriceChange value={rowData.trend7d} />
            </TableCell>
            <TableCell>
              <Box display="flex">
                <Icon icon="chevron-right" />
              </Box>
            </TableCell>
          </React.Fragment>
        )}
        onRowClick={(rowData: Row) => {
          navigate(RouterPaths.PriceFeedDetails.replace(RouterParams.FeedId, rowData.id));
        }}
      />
    </Wrapper>
  );
};

export default PriceFeedsList;
