import { createTheme, darken } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import AtAeroWoff2 from '@assets/fonts/at-aero-medium.woff2';
import AeonikFonoWoff2 from '@assets/fonts/aeonik-fono-medium.woff2';

declare module '@mui/material/styles' {
  interface Palette {
    greys: {
      [k: number]: string;
    };
    accent: {
      [k: string]: string;
    };
  }

  interface PaletteOptions {
    greys: {
      [k: number]: string;
    };
    accent: {
      [k: string]: string;
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
    link: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  label: React.CSSProperties;
  link: React.CSSProperties;
}

const primaryColor = '#CAFF8A';

const themePalette = {
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: primaryColor
    },
    success: {
      main: '#B6F343'
    },
    error: {
      main: '#FF5B87'
    },
    info: {
      main: '#52D1FF'
    },
    text: {
      primary: '#FCFCFD',
      secondary: '#98A2B3'
    },
    accent: {
      hover: darken(primaryColor, 0.1),
      active: darken(primaryColor, 0.2)
    },
    greys: {
      400: '#98A2B3',
      700: '#344054',
      800: '#182230',
      900: '#101828'
    },
    background: {
      default: '#0C111D',
      paper: '#182230'
    }
  }
};

const theme = createTheme({
  ...themePalette,
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h2: {
      fontFamily: 'At Aero',
      fontWeight: 500,
      fontSize: '30px',
      lineHeight: '40px',
      letterSpacing: '-0.6px'
    },
    h3: {
      fontFamily: 'At Aero',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '-0.2px'
    },
    h4: {
      fontFamily: 'At Aero',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '-0.2px'
    },
    h5: {
      fontFamily: 'At Aero',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '-0.2px'
    },
    h6: {
      fontFamily: 'At Aero',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '-0.2px'
    },
    label: {
      fontFamily: 'Aeonik Fono',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.14px',
      textTransform: 'uppercase',
      color: themePalette.palette.greys['400']
    },
    link: {
      fontFamily: 'Aeonik Fono',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.14px',
      color: themePalette.palette.text.primary,
      textDecoration: 'none',
      textTransform: 'uppercase',
      '&:hover': {
        color: themePalette.palette.accent.hover
      },
      '&:active': {
        color: themePalette.palette.accent.active
      },
      '& svg path': {
        fill: 'currentColor'
      }
    }
  } as ExtendedTypographyOptions,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "At Aero";
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local("At Aero"), local("At Aero-Medium"), url(${AtAeroWoff2}) format("woff2");
        }
        @font-face {
          font-family: "Aeonik Fono";
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local("Aeonik Fono"), local("Aeonik Fono-Medium"), url(${AeonikFonoWoff2}) format("woff2");
        }
      `
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'scale(1,1)',
          backgroundColor: themePalette.palette.greys['800'],
          '&::after': {
            backgroundColor: themePalette.palette.greys['900']
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          fontFamily: 'Aeonik Fono',
          fontSize: 14,
          lineHeight: '24px',
          letterSpacing: '0.14px',
          borderRadius: 2,
          padding: '11px 23px',
          whiteSpace: 'nowrap',
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                background: 'transparent',
                borderColor: themePalette.palette.greys['700'],
                padding: '11px 23px',

                '&:hover': {
                  backgroundColor: themePalette.palette.greys['800']
                },

                '&:active': {
                  backgroundColor: themePalette.palette.greys['700']
                },

                '&:disabled': {
                  opacity: 0.5
                },

                '& .MuiButton-startIcon': {
                  marginLeft: 0
                }
              }
            }
          ],
          '&.Mui-disabled': {
            opacity: 0.5,
            backgroundColor: themePalette.palette.primary.main,
            '.MuiButton-icon': {
              opacity: 0.5
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                padding: '3px 11px',
                borderRadius: 120,
                background: themePalette.palette.greys['900'],
                borderColor: themePalette.palette.greys['700'],

                span: {
                  padding: 0
                }
              }
            }
          ]
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          defaultProps: {
            disableRipple: true
          },
          borderRadius: 2,
          padding: '2px',
          background: themePalette.palette.background.default,
          '& .MuiToggleButton-root': {
            color: themePalette.palette.text.primary,
            backgroundColor: themePalette.palette.greys['800'],
            textTransform: 'none',
            padding: '8px 24px',
            '&.Mui-selected': {
              backgroundColor: themePalette.palette.primary.main,
              color: 'rgba(0, 0, 0, 0.87)',
              '&:hover': {
                backgroundColor: themePalette.palette.primary.main,
                color: 'rgba(0, 0, 0, 0.87)'
              }
            }
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1536
    }
  }
});

theme.typography.h1 = {
  fontFamily: 'At Aero',
  fontWeight: 500,
  fontSize: '30px',
  lineHeight: '40px',
  letterSpacing: '-0.6px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '48px',
    lineHeight: '48px',
    letterSpacing: '-0.96px'
  }
};

export default theme;
