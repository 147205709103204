import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import Icon from '../icon';
import theme from '../../theme';

const Footer = (): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      height={120}
      paddingTop={{ xs: 5 }}
      paddingBottom={{ xs: 5 }}
      px={{
        xs: 2,
        sm: 5,
        md: 10,
        lg: 15
      }}
      bgcolor="greys.900"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between">
      <Box display="flex" gap={{ xs: 4, md: 10 }} flexWrap="wrap">
        <Typography
          variant="link"
          component="a"
          href="https://docs.edgebychaos.com/"
          target="_blank"
          rel="noreferrer"
          display="flex"
          alignItems="center">
          Docs
        </Typography>
        <Typography
          variant="link"
          component="a"
          href="https://community.chaoslabs.xyz/privacy"
          target="_blank"
          rel="noreferrer"
          display="flex"
          alignItems="center">
          Privacy
        </Typography>
        <Typography
          variant="link"
          component="a"
          href="https://community.chaoslabs.xyz/terms"
          target="_blank"
          rel="noreferrer"
          display="flex"
          alignItems="center">
          Terms of Service
        </Typography>
      </Box>
      <Box>
        <Box display="flex" alignItems="center" gap={3.5}>
          {!isMobile && (
            <Typography variant="h4" color="text.primary">
              FOLLOW US
            </Typography>
          )}
          <IconButton
            size="small"
            sx={{ p: '0 !important' }}
            target="_blank"
            href="https://twitter.com/chaos_labs">
            <Icon icon="twitter" />
          </IconButton>
          <IconButton
            size="small"
            sx={{ p: '0 !important' }}
            target="_blank"
            href="https://linkedin.com/company/chaos-labs-xyz">
            <Icon icon="linkedin" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
