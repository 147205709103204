import React, { ReactNode, useMemo, useState } from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import Grid from '@mui/system/Grid';

import Card from './card';
import Icon from '@components/icon';
import { useClientsData } from '@components/clients/use-clients-data';

interface ClientsProps {
  supportedProtocols?: string[];
  hideSearch?: boolean;
  loading?: boolean;
}

const Clients = ({ supportedProtocols, hideSearch, loading }: ClientsProps): ReactNode => {
  const { clients: data } = useClientsData();

  const [query, setQuery] = useState<string>('');

  const clients = useMemo(() => {
    return data.filter(
      ({ slug, title }) =>
        loading ||
        (supportedProtocols?.includes(slug) &&
          (query ? title.toLowerCase().includes(query.toLowerCase()) : true))
    );
  }, [data, query, supportedProtocols, loading]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        bgcolor="greys.900"
        padding={3}
        borderBottom="1px solid"
        borderColor="greys.800"
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
          rowGap: {
            xs: 2,
            md: 0
          }
        }}>
        <Typography variant="h2">Clients</Typography>
        <Box
          position="relative"
          sx={{
            width: {
              xs: '100%',
              md: 288
            }
          }}>
          {!hideSearch && (
            <TextField
              value={query}
              placeholder="Search"
              fullWidth
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon="filter" />
                    </InputAdornment>
                  )
                }
              }}
              onChange={handleSearch}
            />
          )}
          <Box
            display="flex"
            position="absolute"
            sx={{
              top: 12,
              right: 16,
              cursor: 'pointer',
              transition: 'transform .3s ease',
              transform: `scale(${query && query.trim() !== '' ? 1 : 0})`
            }}
            onClick={() => setQuery('')}>
            <Icon icon="close" />
          </Box>
        </Box>
      </Box>
      <Grid
        container
        sx={{
          mb: {
            xs: 3,
            md: 5
          },
          mr: '-1px'
        }}>
        {clients.length ? (
          clients.map(({ title, icon, slug }) => (
            <Grid
              key={title}
              size={{ xs: 12, md: 6, lg: 6 }}
              borderRight="1px solid"
              borderBottom="1px solid"
              borderColor="greys.800">
              <Card title={title} icon={icon} slug={slug} loading={loading} />
            </Grid>
          ))
        ) : (
          <Grid size={12} borderBottom="1px solid" borderColor="greys.800">
            <Typography textAlign="center" py={10} variant="h2">
              Not found
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Clients;
