import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Icon from '@components/icon';

interface PageTitleProps {
  children: React.ReactNode;
  backTo?: string;
  state?: unknown;
}

const PageTitle = ({ children, backTo, state }: PageTitleProps): JSX.Element => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(backTo ?? '/', {
      state,
      replace: true
    });
  };

  return (
    <Box display="flex" columnGap={2} alignItems="center">
      <Box display="flex" onClick={handleBack}>
        <Icon icon="chevron-left" />
      </Box>
      <Typography variant="h2" display="flex" columnGap={1} alignItems="center">
        {children}
      </Typography>
    </Box>
  );
};

export default PageTitle;
