import React, { useMemo } from 'react';
import Grid from '@mui/system/Grid';
import { Box, Skeleton, Typography } from '@mui/material';
import InfoCard from '@components/info-card';
import Wrapper from '@components/wrapper';
import PageTitle from '@components/page-title';
import Price from '@components/price';
import PriceChart from './price-chart';
import DataProviders from './data-providers';
import { useParams } from 'react-router-dom';
import { useFeedsWebSocket } from '../../api/use-web-socket';
import { ApiEndpoint, Feed, useApiRequest, WsEndpoint } from '../../api';
import { formatAmount, truncateMiddle } from '../../utils/formatters';
import CryptoIcon from '../../components/crypto-icon';
import { RouterPaths } from '../../types/routers';
import { Heartbeat } from '@components/heartbeat';
import { getAddressUrl } from '@utils/chain-url-mapper';
import Icon from '@components/icon';

const get1hChangeFromFeed = (feed: Feed) => {
  return feed.prices.find((p) => p.interval === '24h')?.diff;
};
const PriceFeedsDetails = (): JSX.Element => {
  const { id } = useParams();
  const params = useMemo(
    () => ({
      feed: id!.replace('-', '')
    }),
    []
  );
  const { response, isLoading } = useApiRequest(ApiEndpoint.Feeds, params);
  const lastPriceMessage = useFeedsWebSocket(WsEndpoint.Feeds, params);

  const feed = lastPriceMessage?.[0] || response?.feeds[0];

  const cards = [
    {
      title: 'Asset Type',
      value: 'Crypto'
    },
    {
      title: 'Market Hours',
      value: '24/7/365'
    },
    ...(feed?.type === 'push'
      ? [
          {
            title: 'Networks',
            value: feed.network && <CryptoIcon icon={feed.network.toLowerCase()} />
          },
          {
            title: 'Heartbeat',
            value: <Heartbeat nextTimestamp={feed.nextTimestamp} />
          },
          {
            title: 'Deviation Threshold',
            value:
              feed.deviation_threshold !== undefined
                ? formatAmount(feed.deviation_threshold / 100, {
                    isPercent: true,
                    notation: 'standard'
                  })
                : '-'
          },
          {
            title: 'Contract Address',
            value: feed.address ? (
              <Typography
                component="a"
                href={getAddressUrl(feed.network!.toLowerCase(), feed.address)}
                target="_blank"
                rel="noreferrer"
                display="flex"
                alignItems="center"
                columnGap={1}
                variant="link"
                fontFamily="Inter">
                {truncateMiddle(feed.address, 4, 4)}
                <Icon icon="external-link" />
              </Typography>
            ) : (
              '-'
            )
          }
        ]
      : []),
    {
      title: 'Market Cap',
      value: formatAmount(feed?.market_cap || 0, {
        isCurrency: true,
        notation: 'standard',
        maximumFractionDigits: 0
      })
    },
    {
      title: 'Circulating Supply',
      value: formatAmount(feed?.circulating_supply || 0, {
        notation: 'standard'
      })
    },
    {
      title: 'Volume (24H)',
      value: formatAmount(feed?.usd_24h_vol || 0, {
        isCurrency: true,
        notation: 'standard',
        maximumFractionDigits: 0
      })
    }
  ];

  return (
    <Box flex="1 0 0" minWidth={0}>
      <Wrapper>
        <Box display="flex" flexDirection="column" rowGap={3} px={3} pt={5} pb={3}>
          <PageTitle
            backTo={RouterPaths.PriceFeeds}
            state={{
              feedType: feed?.type
            }}>
            <CryptoIcon icon={id!.split('-')[0]} />
            {id!.replace('-', ' / ')}
          </PageTitle>
          <Box pl={5}>
            {!isLoading && feed ? (
              <Price change={get1hChangeFromFeed(feed) || 0} changeUp>
                {formatAmount(feed.last_price, {
                  isCurrency: true,
                  notation: 'standard'
                })}
              </Price>
            ) : (
              <Skeleton width={130} height={48} />
            )}
          </Box>
        </Box>
        <Grid container sx={{ px: { xs: 1, lg: 0 } }}>
          {cards.map((card, idx) => (
            <Grid size={{ xs: 6, lg: 4 }} py={2} sx={{ px: { xs: 2, lg: 3 } }} key={idx}>
              <InfoCard title={card.title} isLoading={isLoading}>
                {card.value}
              </InfoCard>
            </Grid>
          ))}
        </Grid>
      </Wrapper>

      {feed && (
        <PriceChart
          feedId={feed.feed}
          network={feed.network}
          address={feed.address}
          wsLastPriceMessage={lastPriceMessage}
          overrideChartPeriods={feed?.type === 'push' ? ['24h', '7d', '30d'] : undefined}
        />
      )}
      {feed?.type !== 'push' && <DataProviders />}
      <Box borderTop="1px solid" borderColor="greys.700" sx={{ marginTop: '-1px' }}>
        <Wrapper>
          <Box
            sx={{
              pb: {
                xs: 3,
                md: 5
              }
            }}
          />
        </Wrapper>
      </Box>
    </Box>
  );
};

export default PriceFeedsDetails;
