import { formatDate } from '@utils/formatters';

interface DateFormatterProps {
  date: Date | string | number;
  dateFormat: string;
}

const DateFormatter = ({ date, dateFormat = 'MM.DD.YYYY' }: DateFormatterProps): JSX.Element => {
  return <time>{formatDate(date, dateFormat)}</time>;
};

export default DateFormatter;
