import React, { useMemo, useState } from 'react';
import Grid from '@mui/system/Grid';
import { Box, TableCell, Typography } from '@mui/material';
import InfoCard from '@components/info-card';
import Wrapper from '@components/wrapper';
import PageTitle from '@components/page-title';
import Table, { Column } from '@components/table';
import Icon from '@components/icon';
import DateFormatter from '@components/date-formatter';
import HistoricalChartDrawer from './historical-chart-drawer';
import { useParams } from 'react-router-dom';
import { RouterPaths } from '../../types/routers';
import { useClientsData } from '@components/clients/use-clients-data';
import { ApiEndpoint, RiskOracleProtocolRecommendation, useApiRequest } from '../../api';
import { formatAmount, truncateMiddle } from '@utils/formatters';
import CryptoIcon from '@components/crypto-icon';
import { getTxDetailsUrl } from '@utils/chain-url-mapper';

type Row = {
  id: number;
  riskParameterName: string;
  chain: string;
  market: string;
  asset?: string;
  poolName?: string;
  recommendedValue: string;
  txHash: string;
  lastOnChainUpdate: number;
  historicalChartData: RiskOracleProtocolRecommendation['historical_chart'];
};

const RiskFeedsDetails = (): JSX.Element => {
  const { clientId } = useParams();
  const { getClientBySlug } = useClientsData();

  const clientData = getClientBySlug(clientId!);

  const params = useMemo(() => ({ protocol: clientId! }), [clientId]);

  const { response: overviewResponse, isLoading: overviewLoading } = useApiRequest(
    ApiEndpoint.RiskOracleProtocolOverview,
    params
  );

  const { response: recommendationsResponse, isLoading: recommendationsLoading } = useApiRequest(
    ApiEndpoint.RiskOracleProtocolRecommendations,
    params
  );

  const [selectedRowForHistoricalChart, setSelectedRowForHistoricalChart] =
    useState<RiskOracleProtocolRecommendation | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = (index: number) => {
    setSelectedRowForHistoricalChart(recommendationsResponse?.[index] ?? null);
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedRowForHistoricalChart(null);
  };

  const rows = useMemo<Row[]>(
    () =>
      (recommendationsResponse ?? []).map((record, index) => ({
        id: index,
        riskParameterName: record.risk_parameter_name,
        chain: record.market.chain,
        market: record.market.market,
        poolName: record.market.pool_name,
        asset: record.asset,
        recommendedValue: formatAmount(record.recommended_value, {
          isCurrency: record.recommended_value_type === 'currency',
          isPercent: record.recommended_value_type === 'percent',
          notation: 'standard'
        }),
        txHash: record.tx_hash,
        lastOnChainUpdate: record.last_on_chain_update * 1000,
        historicalChartData: record.historical_chart
      })),
    [recommendationsResponse]
  );

  const columns = useMemo<Column<Row>[]>(() => {
    return [
      {
        sortBy: 'riskParameterName',
        label: 'Parameter Name'
      },
      {
        sortBy: 'market',
        label: 'Market'
      },
      recommendationsResponse?.[0]?.market?.pool_name
        ? {
            sortBy: 'poolName',
            label: 'Pool Name'
          }
        : {
            sortBy: 'asset',
            label: 'Asset'
          },
      {
        sortBy: 'recommendedValue',
        label: 'Recommended Value'
      },
      {
        sortBy: 'txHash',
        label: 'Tx Hash'
      },
      {
        sortBy: 'lastOnChainUpdate',
        label: 'Last On-chain Update'
      },
      {
        label: 'Historical Chart'
      }
    ];
  }, [recommendationsResponse]);

  return (
    <React.Fragment>
      <Wrapper>
        <Box display="flex" flexDirection="column" rowGap={3} px={3} pt={5} pb={3}>
          <PageTitle backTo={RouterPaths.RiskFeeds}>
            <Box display="flex" alignItems="center" columnGap={2}>
              <Box
                component="img"
                src={clientData?.icon}
                width={56}
                height={56}
                alt={clientData?.title}
              />
              <Box display="flex" alignItems="center" position="relative">
                <Typography variant="h1">{clientData?.title}</Typography>
                <Typography
                  variant="label"
                  position="absolute"
                  left={0}
                  bottom={-24}
                  whiteSpace="nowrap">
                  {clientData?.category}
                </Typography>
              </Box>
            </Box>
          </PageTitle>
        </Box>
        <Grid container sx={{ pt: { xs: 0, md: 3 } }}>
          <Grid size={{ xs: 6, lg: 3 }} py={2} px={3}>
            <InfoCard title="Protocol Total Value Secured" isLoading={overviewLoading}>
              {formatAmount(overviewResponse?.total_value_secured ?? 0, {
                isCurrency: true,
                notation: 'standard'
              })}
            </InfoCard>
          </Grid>
          {/*<Grid size={{ xs: 6, lg: 3 }} py={2} px={3}>*/}
          {/*  <InfoCard title="Risk Oracle Uptime" isLoading={overviewLoading}>*/}
          {/*    {formatAmount((overviewResponse?.risk_oracle_uptime ?? 0) / 100, {*/}
          {/*      isPercent: true,*/}
          {/*      notation: 'standard',*/}
          {/*      maximumFractionDigits: 3*/}
          {/*    })}*/}
          {/*  </InfoCard>*/}
          {/*</Grid>*/}
          <Grid size={{ xs: 6, lg: 3 }} py={2} px={3}>
            <InfoCard title="Assets secured" isLoading={overviewLoading}>
              {overviewResponse?.total_assets_secured}
            </InfoCard>
          </Grid>
          <Grid size={{ xs: 6, lg: 3 }} py={2} px={3}>
            <InfoCard title="Number of blockchains" isLoading={overviewLoading}>
              {overviewResponse?.total_blockchains}
            </InfoCard>
          </Grid>
        </Grid>
        <Box>
          <Table<Row>
            columns={columns}
            rows={rows}
            rowKey="id"
            loading={recommendationsLoading}
            title="Risk Parameter"
            rowContent={(rowData: Row) => (
              <React.Fragment>
                <TableCell>{rowData.riskParameterName}</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center" columnGap={1}>
                    <CryptoIcon icon={rowData.chain.split('-')[0]} />
                    <Box display="flex" flexDirection="column">
                      <Typography
                        sx={{
                          textTransform: 'uppercase'
                        }}>
                        {rowData.market}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{
                          '&:first-letter': {
                            textTransform: 'uppercase'
                          }
                        }}>
                        {rowData.chain}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center" columnGap={1}>
                    {rowData.asset && <CryptoIcon icon={rowData.asset.split('-')[0]} />}
                    <Typography
                      sx={{
                        textTransform: 'uppercase'
                      }}>
                      {rowData.poolName?.replace('-', ' / ') ?? rowData.asset}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{rowData.recommendedValue}</TableCell>
                <TableCell>
                  <Typography
                    component="a"
                    href={getTxDetailsUrl(rowData.chain, rowData.txHash)}
                    target="_blank"
                    rel="noreferrer"
                    display="flex"
                    alignItems="center"
                    columnGap={1}
                    variant="link"
                    fontFamily="Inter">
                    {truncateMiddle(rowData.txHash, 4, 4)}
                    <Icon icon="external-link" />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    <DateFormatter date={rowData.lastOnChainUpdate} dateFormat="MMM DD, YYYY" />
                  </Typography>
                  <Typography color="greys.400" fontSize={12}>
                    <DateFormatter date={rowData.lastOnChainUpdate} dateFormat="hh:mm A [UTC]" />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="link"
                    display="inline-flex"
                    columnGap={1}
                    color="primary.main"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleDrawerOpen(rowData.id)}>
                    <Icon icon="full-screen" />
                  </Typography>
                </TableCell>
              </React.Fragment>
            )}
          />
        </Box>
      </Wrapper>
      <HistoricalChartDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        recommendation={selectedRowForHistoricalChart}
      />
    </React.Fragment>
  );
};

export default RiskFeedsDetails;
