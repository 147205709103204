import React from 'react';
import { ReactComponent as Logo } from '@assets/logo.svg';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Wrapper from '@components/wrapper';
import { Link } from 'react-router-dom';
import Icon from '@components/icon';
import theme from '../../theme';

const Header = (): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="header"
      sx={{
        bgcolor: 'greys.900'
      }}
      borderBottom="1px solid"
      borderColor="greys.700">
      <Wrapper style={{ border: 'none' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" py={1.5} px={3}>
          <Link to="/">
            <Logo color={theme.palette.primary.main} width={162} height={40} />
          </Link>
          {isMobile ? (
            <Typography
              variant="link"
              component="a"
              href="https://chaoslabs.xyz/"
              target="_blank"
              rel="noreferrer"
              display="flex"
              alignItems="center"
              sx={{
                columnGap: '4px'
              }}>
              Main Site
              <Icon icon="arrow-external" />
            </Typography>
          ) : (
            <Box display="flex" gap={3}>
              <Typography
                variant="link"
                component="a"
                href="https://chaoslabs.xyz/contact-us"
                target="_blank"
                rel="noreferrer"
                display="flex"
                alignItems="center">
                Contact Us
              </Typography>
              <Typography
                variant="link"
                component="a"
                href="https://chaoslabs.xyz/"
                target="_blank"
                rel="noreferrer"
                display="flex"
                alignItems="center"
                sx={{
                  columnGap: '4px'
                }}>
                Chaos Labs Website
                <Icon icon="arrow-external" />
              </Typography>
            </Box>
          )}
        </Box>
      </Wrapper>
    </Box>
  );
};

export default Header;
