import { useEffect, useMemo, useState } from 'react';
import { ApiEndpoint, ApiParams, ApiResponse } from './types';
import API from './api';

export type ReturnType<T> = {
  isLoading: boolean;
  response?: T;
};

export const useApiRequest = <T extends ApiEndpoint = ApiEndpoint>(
  endpoint: T,
  params?: ApiParams<T>,
  skipRequest?: boolean
): ReturnType<ApiResponse<T>> => {
  const [response, setResponse] = useState<ApiResponse<T>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (skipRequest) {
        return;
      }
      setIsLoading(true);
      const res = await API.get<ApiResponse<T>>(endpoint, {
        params
      });
      setResponse(res.data);
      setIsLoading(false);
    })();
  }, [params, skipRequest]);

  const returnObj = useMemo(
    () => ({
      response,
      isLoading
    }),
    [response, isLoading]
  );

  return returnObj;
};
