import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Typography,
  useTheme
} from '@mui/material';
import Wrapper from '@components/wrapper';
import Grid from '@mui/system/Grid';

import { ReactComponent as PriceImage } from '@assets/images/price.svg';
import { ReactComponent as RiskImage } from '@assets/images/risk.svg';
import { ReactComponent as ProofsImage } from '@assets/images/proofs.svg';
import Icon from '@components/icon';
import React from 'react';
import { RouterPaths } from '../../types/routers';

const LandingPage = () => {
  const theme = useTheme();

  const dashboards = [
    {
      key: 'price',
      title: 'Price',
      description:
        'Edge Price Oracles provide high-precision price data with an integrated risk engine powered by Chaos Labs’ advanced outlier and anomaly detection models',
      image: <PriceImage color={theme.palette.primary.main} width="100%" />,
      navigateTo: RouterPaths.PriceFeeds
    },
    {
      key: 'risk',
      title: 'Risk',
      description:
        'Edge Risk Oracles unlock real-time risk management with automated parameter optimization',
      image: <RiskImage color={theme.palette.primary.main} width="100%" />,
      navigateTo: RouterPaths.RiskFeeds
    },
    {
      key: 'proofs',
      title: (
        <Box display="flex" alignItems="center">
          <span>Proofs</span>
          <Chip
            label="COMING SOON"
            color="primary"
            size="small"
            sx={{
              marginLeft: 2,
              borderRadius: 0
            }}
          />
        </Box>
      ),
      description:
        'Edge Proofs enable secure on-chain delivery of custom data points, including reserve attestations delivered via zero-knowledge proofs',
      image: <ProofsImage color={theme.palette.primary.main} width="100%" />,
      navigateTo: undefined
    }
  ];

  return (
    <Wrapper>
      <Box display="flex" flexDirection="column" height="100%">
        <Typography fontSize={48} fontWeight={500} p={4} pt={6} variant="h2">
          Edge Protocol
        </Typography>
        <Typography variant="subtitle1" pl={4}>
          The advanced decentralized oracle protocol delivering real-time contextualized data for
          any on-chain application
        </Typography>
        <Grid container sx={{ marginLeft: '-1px', marginTop: 4, width: '100%', height: '100%' }}>
          {dashboards.map((feed, index) => (
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              size={{ xs: 12, lg: 4 }}
              key={feed.key}
              p={2}
              borderRight={index !== dashboards.length - 1 ? '1px solid' : '0'}
              borderTop="1px solid"
              borderColor="greys.700">
              <Card
                sx={{
                  background: 'transparent',
                  border: '0',
                  boxShadow: 'none'
                }}>
                <CardMedia>{feed.image}</CardMedia>
                <CardHeader title={<Typography variant="h2"> {feed.title} </Typography>} />
                <CardContent
                  sx={{
                    height: 140
                  }}>
                  {feed.description}
                </CardContent>
                <CardActions>
                  <Button
                    endIcon={
                      <Icon
                        icon="chevron-right"
                        color={theme.palette.getContrastText(theme.palette.primary.main)}
                      />
                    }
                    variant="contained"
                    href={feed.navigateTo}
                    disabled={!feed.navigateTo}
                    color="primary">
                    View Dashboard
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default LandingPage;
