import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from '@components/layout';
import PriceFeedsList from '@pages/price-feeds-list';
import PriceFeedsDetails from '@pages/price-feeds-details';
import { RouterPaths } from './types/routers';
import RiskFeeds from '@pages/risk-feeds';
import RiskFeedsDetails from '@pages/risk-feeds-details';
import LandingPage from '@pages/landing';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to={RouterPaths.Landing} replace />} />
        <Route path={RouterPaths.Landing} element={<LandingPage />} />
        <Route path={RouterPaths.PriceFeeds} element={<PriceFeedsList />} />
        <Route path={RouterPaths.PriceFeedDetails} element={<PriceFeedsDetails />} />
        <Route path={RouterPaths.RiskFeeds} element={<RiskFeeds />} />
        <Route path={RouterPaths.RiskFeedDetails} element={<RiskFeedsDetails />} />
        <Route path="*" element={<Navigate to={RouterPaths.Landing} replace />} />
      </Route>
    </Routes>
  );
}

export default App;
