import React from 'react';
import { Box, Typography } from '@mui/material';

import PriceChange from '@components/price-change';

interface PriceProps {
  children: React.ReactNode;
  change: number;
  changeUp?: boolean;
}

const Price = ({ children, change, changeUp }: PriceProps): JSX.Element => {
  return (
    <Box display="flex" columnGap={1} alignItems="flex-end">
      <Typography variant="h1" display="flex" columnGap={1} alignItems="center">
        {children}
      </Typography>
      <PriceChange value={change} changeUp={changeUp} />
    </Box>
  );
};

export default Price;
