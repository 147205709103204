import useWebSocket from 'react-use-websocket';
import { WsData, WsEndpoint, WsParams } from './types';
import { useMemo } from 'react';

export const useFeedsWebSocket = <T extends WsEndpoint = WsEndpoint>(
  endpoint: T,
  params?: WsParams<T>
): WsData<T> | null => {
  const { lastMessage } = useWebSocket(`${process.env.REACT_APP_WS_BASE_URL}/${endpoint}`, {
    queryParams: params
  });

  const message = useMemo(() => {
    return lastMessage?.data ? (JSON.parse(lastMessage.data) as WsData<T>) : null;
  }, [lastMessage]);
  return message;
};
