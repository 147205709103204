import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

interface RelativeTimeProps {
  date: Date | number;
}

const RelativeTime = ({ date }: RelativeTimeProps): string => {
  const diff = dayjs(new Date()).diff(date, 'millisecond');
  return `${diff < 0 ? 0 : diff} ms`;
};

export default RelativeTime;
