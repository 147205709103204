import React, { useMemo } from 'react';
import { Box, DialogContent, DialogTitle, Drawer, TableCell, Typography } from '@mui/material';
import Icon from '@components/icon';
import dayjs from 'dayjs';
import ValueOverTimeChart from '@components/value-over-time-chart';
import { RiskOracleProtocolRecommendation } from '../../api';
import Grid from '@mui/system/Grid';
import InfoCard from '@components/info-card';
import CryptoIcon from '@components/crypto-icon';
import { formatAmount, truncateMiddle } from '@utils/formatters';
import Table, { Column } from '@components/table';
import { getTxDetailsUrl } from '@utils/chain-url-mapper';
import DateFormatter from '@components/date-formatter';

interface HistoricalChartDrawerProps {
  open: boolean;
  onClose: () => void;
  recommendation: RiskOracleProtocolRecommendation | null;
}

type RecommendedValueRow = {
  id: number;
  timestamp: number;
  recommendedValue: string;
  txHash: string;
};

const HistoricalChartDrawer: React.FC<HistoricalChartDrawerProps> = ({
  open,
  onClose,
  recommendation
}) => {
  if (!open || !recommendation) return null;

  const rows = useMemo<RecommendedValueRow[]>(
    () =>
      (recommendation?.historical_chart ?? []).map((record, index) => ({
        id: index,
        timestamp: record.timestamp * 1000,
        recommendedValue: formatAmount(record.value, {
          isCurrency: recommendation.recommended_value_type === 'currency',
          isPercent: recommendation.recommended_value_type === 'percent',
          notation: 'standard'
        }),
        txHash: record.tx_hash
      })),
    [recommendation]
  );

  const columns: Column<RecommendedValueRow>[] = [
    {
      sortBy: 'timestamp',
      label: 'Date'
    },
    {
      sortBy: 'recommendedValue',
      label: 'Recommended Value'
    },
    {
      sortBy: 'txHash',
      label: 'Tx Hash'
    }
  ];

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { minWidth: 600 } }}>
      <DialogTitle>
        <Box display="flex" flexDirection="column" pl={3} pt={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">
              {recommendation.risk_parameter_name} Historical Chart
            </Typography>
            <Typography variant="link" onClick={onClose} sx={{ cursor: 'pointer' }}>
              <Icon icon="close" />
            </Typography>
          </Box>
          <Typography variant="subtitle1" color="text.secondary">
            Risk Parameter Updated {recommendation.historical_chart?.length ?? 0} Times
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container pb={3}>
          <Grid size={6} py={2} px={3}>
            <InfoCard title="Market">
              <Box display="flex" alignItems="center" columnGap={1}>
                <Typography
                  sx={{
                    textTransform: 'uppercase'
                  }}>
                  {recommendation?.market.market}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {' '}
                  on{' '}
                </Typography>
                <CryptoIcon icon={recommendation?.market.chain.split('-')[0]} />
                <Typography
                  sx={{
                    '&:first-letter': {
                      textTransform: 'uppercase'
                    }
                  }}>
                  {recommendation?.market.chain}
                </Typography>
              </Box>
            </InfoCard>
          </Grid>
          <Grid size={6} py={2} px={3}>
            <InfoCard title={recommendation?.market?.pool_name ? 'Pool Name' : 'Asset'}>
              <Box display="flex" alignItems="center" columnGap={1}>
                {recommendation?.asset && <CryptoIcon icon={recommendation?.asset.split('-')[0]} />}
                <Typography
                  sx={{
                    textTransform: 'uppercase'
                  }}>
                  {recommendation?.market?.pool_name?.replace('-', ' / ') ?? recommendation?.asset}
                </Typography>
              </Box>
            </InfoCard>
          </Grid>
        </Grid>
        <ValueOverTimeChart
          series={[
            {
              data:
                recommendation.historical_chart?.map((r) => ({
                  date: dayjs(r.timestamp * 1000).toDate(),
                  value: r.value
                })) || [],
              showSymbol: true,
              symbolSize: 6
            }
          ]}
          xAxisLabel="DATE"
          valueFormatterConfig={{
            isCurrency: recommendation.recommended_value_type === 'currency',
            isPercent: recommendation.recommended_value_type === 'percent',
            notation: 'compact'
          }}
          timeRange="24h"
        />
        <Table<RecommendedValueRow>
          columns={columns}
          rows={rows}
          rowKey="id"
          loading={!recommendation}
          title="Recommended Values"
          rowContent={(rowData: RecommendedValueRow) => (
            <React.Fragment>
              <TableCell>
                <Typography>
                  <DateFormatter date={rowData.timestamp} dateFormat="MMM DD, YYYY" />
                </Typography>
                <Typography color="greys.400" fontSize={12}>
                  <DateFormatter date={rowData.timestamp} dateFormat="hh:mm A [UTC]" />
                </Typography>
              </TableCell>
              <TableCell>{rowData.recommendedValue}</TableCell>
              <TableCell>
                <Typography
                  component="a"
                  href={getTxDetailsUrl(recommendation?.market.chain, rowData.txHash)}
                  target="_blank"
                  rel="noreferrer"
                  display="flex"
                  alignItems="center"
                  columnGap={1}
                  variant="link"
                  fontFamily="Inter">
                  {truncateMiddle(rowData.txHash, 4, 4)}
                  <Icon icon="external-link" />
                </Typography>
              </TableCell>
            </React.Fragment>
          )}
        />
      </DialogContent>
    </Drawer>
  );
};

export default HistoricalChartDrawer;
