import DefaultIcon from '../../assets/crypto-icons/default.svg';
import BitcoinImage from '../../assets/crypto-icons/bitcoin.svg';
import BinanceImage from '../../assets/crypto-icons/binance.svg';
import CoinbaseImage from '../../assets/crypto-icons/coinbase.svg';
import KrakenImage from '../../assets/crypto-icons/kraken.svg';
import BybitImage from '../../assets/crypto-icons/bybit.svg';
import KucoinImage from '../../assets/crypto-icons/kucoin.svg';
import BitstampImage from '../../assets/crypto-icons/bitstamp.svg';
import EthImage from '../../assets/crypto-icons/eth.svg';
import StethImage from '../../assets/crypto-icons/steth.svg';
import SolImage from '../../assets/crypto-icons/sol.svg';
import UsdcImage from '../../assets/crypto-icons/usdc.svg';
import UsdtImage from '../../assets/crypto-icons/usdt.svg';
import AvaxImage from '../../assets/crypto-icons/avax.svg';
import ArbImage from '../../assets/crypto-icons/arb.svg';
import AvalancheImage from '../../assets/crypto-icons/avalanche.svg';
import GmxImage from '../../assets/crypto-icons/gmx.svg';
import AusdImage from '../../assets/crypto-icons/ausd.svg';
import AaveImage from '../../assets/crypto-icons/aave.svg';
import WifImage from '../../assets/crypto-icons/wif.svg';
import NearImage from '../../assets/crypto-icons/near.svg';
import OrdiImage from '../../assets/crypto-icons/ordi.svg';

export const getCryptoIconSrc: (iconName: string) => string = (iconName) => {
  const loweCaseIconName = iconName.toLowerCase();
  switch (loweCaseIconName) {
    case 'aave':
      return AaveImage;
    case 'ausd':
      return AusdImage;
    case 'btc':
    case 'wbtc':
      return BitcoinImage;
    case 'eth':
    case 'weth':
      return EthImage;
    case 'steth':
      return StethImage;
    case 'usdc':
      return UsdcImage;
    case 'usdt':
      return UsdtImage;
    case 'sol':
      return SolImage;
    case 'avax':
      return AvaxImage;
    case 'arbitrum':
    case 'arb':
      return ArbImage;
    case 'bnb':
    case 'binance':
      return BinanceImage;
    case 'coinbase':
      return CoinbaseImage;
    case 'kraken':
      return KrakenImage;
    case 'bybit':
      return BybitImage;
    case 'kucoin':
      return KucoinImage;
    case 'bitstamp':
      return BitstampImage;
    case 'avalanche':
      return AvalancheImage;
    case 'gmx':
      return GmxImage;
    case 'wif':
    case '$wif':
      return WifImage;
    case 'near':
      return NearImage;
    case 'ordi':
      return OrdiImage;
    default:
      return DefaultIcon;
  }
};
