import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/system/Grid';
import Wrapper from '@components/wrapper';
import InfoCard from '@components/info-card';
import PriceChange from '@components/price-change';
import { formatAmount } from '@utils/formatters';
import ValueOverTimeChart, { ValueOverTimeChartData } from '../../components/value-over-time-chart';
import dayjs from 'dayjs';
import { ApiEndpoint, ApiParams, Feed, TimeRange, useApiRequest } from '../../api';

const chartPeriods: Record<string, TimeRange | 'live'> = {
  Live: 'live',
  '1H': '1h',
  '24H': '24h',
  '7D': '7d',
  '30D': '30d'
};

const NUMBER_OF_LIVE_WINDOW_POINTS = 5 * 60;

const CardGridWrapper = ({ children }: { children: ReactNode }) => (
  <Grid size={{ xs: 6, lg: 3 }} py={1} px={3}>
    {children}
  </Grid>
);

type Props = {
  feedId: string;
  network?: string;
  address?: string;
  wsLastPriceMessage: Feed[] | null;
  overrideChartPeriods?: (TimeRange | 'live')[];
};

const PriceChart = ({
  feedId,
  network,
  address,
  wsLastPriceMessage,
  overrideChartPeriods
}: Props) => {
  const [livePrices, setLivePrices] = useState<ValueOverTimeChartData[]>([]);
  const [period, setPeriod] = useState<TimeRange | 'live'>(
    overrideChartPeriods && overrideChartPeriods.length > 0
      ? overrideChartPeriods[0]
      : chartPeriods['24H']
  );

  const params = useMemo(
    (): ApiParams<ApiEndpoint.FeedsData> | undefined =>
      period === 'live'
        ? undefined
        : {
            interval: period,
            type: period === '1h' ? '1' : '1h',
            feed: feedId.replace('-', ''),
            ...(network && { network }),
            ...(address && { address })
          },
    [period, network, address, feedId]
  );

  const { response, isLoading } = useApiRequest(ApiEndpoint.FeedsData, params, !params);

  useEffect(() => {
    if (wsLastPriceMessage?.length) {
      const newChartPoint: ValueOverTimeChartData = {
        date: new Date(),
        value: Number(wsLastPriceMessage[0].last_price)
      };
      setLivePrices([...livePrices, newChartPoint]);
    }
  }, [wsLastPriceMessage]);

  const livePriceData = useMemo(() => {
    const sortedPrices = [...livePrices].sort((a, b) => a.date.getTime() - b.date.getTime());
    const prices = livePrices.map((p) => p.value);
    const total = prices.reduce((total, p) => total + p, 0);
    const numberOfPrices = sortedPrices.length;

    return {
      min: Math.min(...prices),
      max: Math.max(...prices),
      average: numberOfPrices ? total / numberOfPrices : undefined,
      diff:
        numberOfPrices > 1
          ? ((sortedPrices[numberOfPrices - 1].value - sortedPrices[0].value) * 100) /
            sortedPrices[0].value
          : undefined
    };
  }, [livePrices]);

  const cardsData = useMemo(() => {
    const isLive = period === 'live';
    return {
      min: isLive ? livePriceData.min : response?.min,
      max: isLive ? livePriceData.max : response?.max,
      average: isLive ? livePriceData.average : response?.average,
      diff: isLive ? livePriceData.diff : response?.diff
    };
  }, [livePriceData, response, period]);

  return (
    <Box borderTop="1px solid" borderColor="greys.700" sx={{ marginTop: '-1px' }}>
      <Wrapper>
        <Box bgcolor="greys.900">
          <Box
            display="flex"
            justifyContent="space-between"
            px={3}
            sx={{
              py: {
                xs: 2,
                lg: 3
              },
              flexDirection: {
                xs: 'column',
                md: 'row'
              },
              alignItems: {
                md: 'center'
              }
            }}>
            <Typography variant="h3">Price Chart</Typography>
            <Box
              display="flex"
              columnGap={2}
              sx={{
                mt: {
                  xs: 1,
                  md: 0
                }
              }}>
              {Object.entries(chartPeriods)
                .filter(
                  ([, value]) =>
                    !overrideChartPeriods ||
                    (overrideChartPeriods &&
                      overrideChartPeriods.length > 0 &&
                      overrideChartPeriods.includes(value))
                )
                .map(([label, value]) => (
                  <Typography
                    variant="link"
                    key={label}
                    fontWeight={500}
                    onClick={() => {
                      setPeriod(value);
                    }}
                    sx={{
                      cursor: 'pointer',
                      color: period === value ? 'primary.main' : 'text.primary'
                    }}>
                    {label}
                  </Typography>
                ))}
            </Box>
          </Box>
          <Grid container sx={{ marginLeft: '-1px', marginTop: { xs: '-1px', lg: 0 } }}>
            <CardGridWrapper>
              <InfoCard title="Minimum price" isLoading={!cardsData.min}>
                <Typography fontWeight={600}>
                  {formatAmount(cardsData.min || 0, { isCurrency: true, notation: 'standard' })}
                </Typography>
              </InfoCard>
            </CardGridWrapper>
            <CardGridWrapper>
              <InfoCard title="Maximum price" isLoading={!cardsData.max}>
                <Typography fontWeight={600}>
                  {formatAmount(cardsData.max || 0, { isCurrency: true, notation: 'standard' })}
                </Typography>
              </InfoCard>
            </CardGridWrapper>
            <CardGridWrapper>
              <InfoCard title="Average price" isLoading={!cardsData.average}>
                <Typography fontWeight={600}>
                  {formatAmount(cardsData.average || 0, { isCurrency: true, notation: 'standard' })}
                </Typography>
              </InfoCard>
            </CardGridWrapper>
            <CardGridWrapper>
              <InfoCard title="Price change">
                {cardsData.diff !== undefined ? (
                  <PriceChange value={cardsData.diff || 0} />
                ) : (
                  <Skeleton />
                )}
              </InfoCard>
            </CardGridWrapper>
          </Grid>
        </Box>
        <Box
          borderTop="1px solid"
          borderColor="greys.700"
          sx={{
            p: {
              xs: 2,
              lg: 3
            }
          }}>
          {period === 'live' ? (
            <ValueOverTimeChart
              key="live-chart"
              series={[{ data: livePrices.slice(-NUMBER_OF_LIVE_WINDOW_POINTS) }]}
              xAxisLabel="DATE"
              yAxisLabel="PRICE"
              valueFormatterConfig={{
                isCurrency: true
              }}
              timeRange={period}
              isLoading={isLoading}
              enableAnimation
            />
          ) : (
            <ValueOverTimeChart
              key="history-chart"
              series={[
                {
                  // name: 'Avg',
                  data: (response?.result || []).map((r) => ({
                    date: dayjs(r.time).toDate(),
                    value: r.close
                  }))
                }
              ]}
              xAxisLabel="DATE"
              yAxisLabel="PRICE"
              valueFormatterConfig={{
                isCurrency: true
              }}
              timeRange={period}
              isLoading={isLoading}
            />
          )}
        </Box>
      </Wrapper>
    </Box>
  );
};

export default PriceChart;
