import React, { useEffect, useMemo, useState } from 'react';
import { Box, Skeleton, TableCell, Typography } from '@mui/material';
import Grid from '@mui/system/Grid';

import Table, { Column } from '@components/table';
import Wrapper from '@components/wrapper';
import InfoCard from '@components/info-card';
import RelativeTime from '@components/relative-time';
import { useParams } from 'react-router-dom';
import { ApiEndpoint, Provider, useApiRequest, WsEndpoint } from '../../api';
import { useFeedsWebSocket } from '../../api/use-web-socket';
import CryptoIcon from '../../components/crypto-icon';
import { capitalizeFirstLetter, formatAmount } from '../../utils/formatters';

type Row = {
  name: string;
  price: number;
  timestamp: number;
  failed_counts: number;
  success_rate: number;
};

const columns: Column<Row>[] = [
  {
    label: 'Source Name',
    sortBy: 'name'
  },
  {
    label: 'Reported Price',
    sortBy: 'price'
  },
  {
    label: 'Last Successful Update',
    sortBy: 'timestamp'
  }
];

const DataProviders = (): JSX.Element => {
  const { id } = useParams();
  const feedParam = useMemo(
    () => ({
      feed: id!.replace('-', '')
    }),
    []
  );

  const [providers, setProviders] = useState<Provider[]>([]);

  const { response, isLoading } = useApiRequest(ApiEndpoint.Providers, feedParam);
  const latestProviderUpdate = useFeedsWebSocket(WsEndpoint.Providers, feedParam);

  useEffect(() => {
    if (response) {
      setProviders(response.reports);
    }
  }, [response]);

  useEffect(() => {
    if (latestProviderUpdate) {
      const { provider } = latestProviderUpdate;
      const newProviders = [...providers];
      const pIndex = newProviders.findIndex((p) => p.provider === provider);
      if (pIndex > -1) {
        newProviders[pIndex] = latestProviderUpdate;
      } else {
        newProviders.push(latestProviderUpdate);
      }
      setProviders(newProviders);
    }
  }, [latestProviderUpdate]);

  const tableRows: Row[] = providers.map((p) => ({
    name: capitalizeFirstLetter(p.provider),
    price: p.price,
    timestamp: p.ts,
    failed_counts: p.failed_counts,
    success_rate: p.success_rate
  }));

  return (
    <Box
      display="flex"
      columnGap={1}
      alignItems="flex-end"
      borderTop="1px solid"
      borderColor="greys.700"
      sx={{ marginTop: '-1px' }}>
      <Wrapper>
        <Box pt={5} px={3}>
          <Typography variant="h1" component="h2">
            Data Providers
          </Typography>
        </Box>
        <Grid container mt={3}>
          <Grid size={6} py={2} px={3}>
            <InfoCard title="Total number of providers">
              {providers.length ? providers.length : <Skeleton />}
            </InfoCard>
          </Grid>
        </Grid>
        <Table<Row>
          columns={columns}
          rows={tableRows}
          rowKey="name"
          title="Data Providers"
          defaultSortBy="name"
          defaultSortOrder="asc"
          loading={isLoading}
          rowContent={(rowData: Row) => (
            <React.Fragment>
              <TableCell>
                <Box display="flex" columnGap={1}>
                  <CryptoIcon icon={rowData.name} />
                  {rowData.name}
                </Box>
              </TableCell>
              <TableCell>
                {formatAmount(rowData.price, { isCurrency: true, notation: 'standard' })}
              </TableCell>
              <TableCell>
                <RelativeTime date={rowData.timestamp} />
              </TableCell>
            </React.Fragment>
          )}
        />
      </Wrapper>
    </Box>
  );
};

export default DataProviders;
