const chainToBlockExplorerUrlMapping: { [chain: string]: string } = {
  arbitrum_goerli: 'https://goerli.arbiscan.io/',
  arbitrum_sepolia: 'https://sepolia.arbiscan.io/',
  arbitrum: 'https://arbiscan.io/',
  avalanche: 'https://snowtrace.io/',
  bnb: 'https://bscscan.com/',
  binance: 'https://bscscan.com/',
  fantom: 'https://ftmscan.com/',
  harmony: 'https://explorer.harmony.one/',
  optimism: 'https://optimistic.etherscan.io/',
  polygon: 'https://polygonscan.com/',
  ethereum: 'https://etherscan.io/',
  goerli: 'https://goerli.etherscan.io/',
  metis: 'https://andromeda-explorer.metis.io/',
  base: 'https://base.blockscout.com/',
  evmos: 'https://escan.live/',
  gnosis: 'https://gnosisscan.io/',
  dydxv4: 'https://www.mintscan.io/dydx/',
  zksync: 'https://explorer.zksync.io/',
  manta: 'https://pacific-explorer.manta.network/',
  scroll: 'https://scrollscan.com/',
  blast: 'https://blastscan.io/',
  lina: 'https://lineascan.build/',
  opbnb: 'https://opbnbscan.com/',
  solana: 'https://solscan.io/'
};

export const getTxDetailsUrl = (chain: string, txHash: string) =>
  `${chainToBlockExplorerUrlMapping[chain]}tx/${txHash}`;

export const getAddressUrl = (chain: string, address: string) =>
  `${chainToBlockExplorerUrlMapping[chain]}address/${address}`;
