import React, { FC } from 'react';

import { ReactComponent as ChevronLeft } from '@assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '@assets/icons/chevron-right.svg';
import { ReactComponent as SortNone } from '@assets/icons/sort-none.svg';
import { ReactComponent as SortAsc } from '@assets/icons/sort-asc.svg';
import { ReactComponent as SortDesc } from '@assets/icons/sort-desc.svg';
import { ReactComponent as Filter } from '@assets/icons/filter.svg';
import { ReactComponent as CheckCircle } from '@assets/icons/check-circle.svg';
import { ReactComponent as InfoCircle } from '@assets/icons/info-circle.svg';
import { ReactComponent as ArrowExternal } from '@assets/icons/arrow-external.svg';
import { ReactComponent as ArrowUp } from '@assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-down.svg';
import { ReactComponent as Twitter } from '@assets/icons/twitter.svg';
import { ReactComponent as Mail } from '@assets/icons/mail.svg';
import { ReactComponent as ExternalLink } from '@assets/icons/external-link.svg';
import { ReactComponent as FullScreen } from '@assets/icons/full-screen.svg';
import { ReactComponent as Linkedin } from '@assets/icons/linkedin.svg';
import { ReactComponent as Close } from '@assets/icons/close.svg';
import { useTheme } from '@mui/material';

const iconComponents = {
  'arrow-down': ArrowDown,
  'arrow-external': ArrowExternal,
  'arrow-up': ArrowUp,
  'check-circle': CheckCircle,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  close: Close,
  'external-link': ExternalLink,
  filter: Filter,
  'full-screen': FullScreen,
  'info-circle': InfoCircle,
  linkedin: Linkedin,
  mail: Mail,
  'sort-asc': SortAsc,
  'sort-desc': SortDesc,
  'sort-none': SortNone,
  twitter: Twitter
};

const getIcon = (iconName: keyof typeof iconComponents, color?: string) => {
  const IconComponent = iconComponents[iconName];
  const theme = useTheme();
  return IconComponent ? <IconComponent color={color ?? theme.palette.text.primary} /> : <span />;
};

interface IconProps {
  icon: keyof typeof iconComponents;
  color?: string;
  style?: React.CSSProperties;
}

const Icon: FC<IconProps> = ({ icon, color, ...rest }): JSX.Element =>
  React.cloneElement(getIcon(icon, color), {
    ...rest
  });

export default Icon;
