import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { RouterParams, RouterPaths } from '../../types/routers';

interface CardProps {
  title: string;
  icon: string;
  slug: string;
  loading?: boolean;
}

const Card = ({ title, icon, slug, loading }: CardProps): JSX.Element => {
  return (
    <Box
      component={loading ? Box : Link}
      to={RouterPaths.RiskFeedDetails.replace(RouterParams.ClientId, slug)}
      display="flex"
      flexDirection="column"
      columnGap={2}
      alignItems="center"
      px={3}
      py={2}
      color="primary.main"
      sx={{
        transition: 'background-color .3s ease',
        textDecoration: 'none',
        '&:hover': {
          bgcolor: 'greys.800',
          '.--scale-icon': {
            transform: 'scale(1.0715)'
          },
          '.--color-icon': {
            opacity: 1
          },
          '.--title': {
            transform: 'translateY(2px)'
          }
        }
      }}>
      {loading ? (
        <Skeleton width="100%" height={56} />
      ) : (
        <>
          <Box
            className="--scale-icon"
            display="flex"
            position="relative"
            width={56}
            height={56}
            overflow="hidden"
            sx={{
              transition: 'transform .3s ease'
            }}>
            <Box
              component="img"
              src={icon}
              width={112}
              height={56}
              alt={title}
              sx={{
                filter: 'grayscale(100%) brightness(1000%)'
              }}
            />
            <Box
              className="--color-icon"
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: 56,
                height: 56,
                backgroundImage: `url(${icon})`,
                backgroundPosition: 'right top',
                backgroundSize: 'auto 56px',
                opacity: 0,
                transition: 'opacity .3s ease'
              }}
            />
          </Box>
          <Typography
            className="--title"
            variant="label"
            component="span"
            color="text.primary"
            sx={{ transition: 'transform .3s ease' }}>
            {title}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default Card;
