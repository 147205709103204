import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

interface HeartbeatProps {
  nextTimestamp?: number;
}

export const Heartbeat = ({ nextTimestamp }: HeartbeatProps) => {
  if (!nextTimestamp) {
    return '00:00:00';
  }

  const [timeLeft, setTimeLeft] = useState((nextTimestamp - Date.now()) / 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((nextTimestamp - Date.now()) / 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [nextTimestamp]);

  return timeLeft < 0 ? '00:00:00' : dayjs.duration(timeLeft, 'seconds').format('HH:mm:ss');
};
