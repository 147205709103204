import React from 'react';
import { Box } from '@mui/material';

interface WrapperProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const Wrapper = ({ style, children }: WrapperProps): JSX.Element => {
  return (
    <Box
      width="100%"
      maxWidth="1328px"
      mx="auto"
      display="flex"
      flex="1 0 0"
      sx={{
        px: {
          xs: 0,
          lg: 5
        }
      }}>
      <Box
        flex="1 0 0"
        minWidth={0}
        sx={{
          borderLeft: {
            lg: `1px solid`
          },
          borderRight: {
            lg: '1px solid'
          },
          borderColor: {
            lg: 'greys.700'
          }
        }}
        style={style}>
        {children}
      </Box>
    </Box>
  );
};

export default Wrapper;
