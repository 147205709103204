import ReactECharts from 'echarts-for-react';
import type { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { chartTheme } from './chartTheme';
import { formatAmount, FormatAmountConfig } from '../../utils/formatters';
import { TimeRange } from '../../api';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Box, Skeleton, useTheme } from '@mui/material';
import { useMemo } from 'react';

dayjs.extend(localizedFormat);
echarts.registerTheme('main', chartTheme);

export type ValueOverTimeChartData = {
  date: Date;
  value: number;
};

export type Series = {
  name?: string;
  data: ValueOverTimeChartData[];
  stack?: string;
  stackStrategy?: 'positive';
  hideFromTooltip?: boolean;
  areaColor?: string;
  color?: string;
  lineOpacity?: number;
  showSymbol?: boolean;
  symbolSize?: number;
};

type Props = {
  series: Series[];
  yAxisLabel?: string;
  xAxisLabel?: string;
  valueFormatterConfig?: FormatAmountConfig;
  timeRange?: TimeRange | 'live';
  isLoading?: boolean;
  enableAnimation?: boolean;
};

const ValueOverTimeChart = ({
  series,
  yAxisLabel,
  xAxisLabel,
  valueFormatterConfig,
  timeRange,
  isLoading,
  enableAnimation
}: Props) => {
  const theme = useTheme();

  echarts.registerTheme('main', chartTheme(theme));

  const option: EChartsOption = useMemo(() => {
    const opt: EChartsOption = {
      animation: enableAnimation,
      animationDelay: 0,
      animationDuration: 0,
      animationDelayUpdate: 0,
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'time',
        name: xAxisLabel,
        nameLocation: 'middle',
        nameGap: 60,
        axisLabel: {
          hideOverlap: true,
          margin: 25,
          formatter: (value: number) => {
            if (timeRange === 'live') return dayjs(value).format('LTS');

            if (timeRange === '1h') return dayjs(value).format('LT');

            if (timeRange === '24h') return dayjs(value).format('MM/DD LT');

            return dayjs(value).format('MM/DD');
          }
        }
      },
      yAxis: {
        type: 'value',
        min: 'dataMin',
        name: yAxisLabel,
        nameLocation: 'middle',
        nameGap: 75,
        axisLabel: {
          formatter: (value: number) =>
            formatAmount(value, { notation: 'standard', ...valueFormatterConfig })
        }
      },
      grid: {
        containLabel: !yAxisLabel,
        left: yAxisLabel ? 90 : 10,
        bottom: xAxisLabel ? 80 : undefined
      },
      graphic: [
        {
          type: 'image',
          id: 'logo',
          left: 'center',
          top: 120,
          style: {
            image: '/logo.svg',
            width: 144,
            height: 60
          }
        }
      ],
      series: series.map((s) => ({
        data: s.data.map((d) => [d.date.getTime(), d.value]),
        type: 'line',
        showSymbol: s.showSymbol,
        symbolSize: s.symbolSize,
        name: s.name,
        stack: s.stack,
        symbol: s.showSymbol ? undefined : 'none',
        color: s.color,
        areaStyle: s.areaColor
          ? {
              color: s.areaColor
            }
          : undefined,
        stackStrategy: s.stackStrategy,
        lineStyle: {
          opacity: s.lineOpacity
        },
        tooltip: {
          show: !s.hideFromTooltip,
          valueFormatter: (value) =>
            formatAmount(Number(value), {
              ...valueFormatterConfig,
              notation: 'standard',
              maximumFractionDigits: 6
            })
        }
      }))
    };

    return opt;
  }, [timeRange, isLoading, series]);

  return (
    <Box height={378}>
      {isLoading ? (
        <Skeleton width="100%" height="100%" />
      ) : (
        <ReactECharts option={option} theme="main" style={{ height: '100%', width: '100%' }} />
      )}
    </Box>
  );
};

export default ValueOverTimeChart;
