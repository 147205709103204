import React, { useCallback, useMemo, useState } from 'react';
import {
  Typography,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  Pagination,
  PaginationItem,
  TableRow,
  Table as MUITable,
  Box,
  Button,
  Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Icon from '@components/icon';

function descendingComparator<T>(a: T, b: T, sortBy: keyof T) {
  if (b[sortBy] < a[sortBy]) {
    return -1;
  }
  if (b[sortBy] > a[sortBy]) {
    return 1;
  }
  return 0;
}

function getComparator<T>(order: Order, sortBy: keyof T): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, sortBy)
    : (a, b) => -descendingComparator(a, b, sortBy);
}

type Order = 'asc' | 'desc';

export interface Column<TRow> {
  sortBy?: keyof TRow;
  label?: string;
  width?: number;
}

interface TableProps<TRow, TColumn> {
  rows: TRow[];
  columns: TColumn[];
  rowKey: keyof TRow;
  defaultSortOrder?: Order;
  defaultSortBy?: keyof TRow;
  rowContent: (row: TRow, index: number) => React.ReactNode;
  fixedLayout?: boolean;
  onRowClick?: (data: TRow) => void;
  title?: string | React.ReactNode;
  rowsPerPage?: number;
  loading?: boolean;
}

const StyledHeadRow = styled(TableRow)(({ theme }) => ({
  [`& th.${tableCellClasses.head}`]: {
    borderColor: theme.palette.greys['700'],
    backgroundColor: theme.palette.greys['900'],
    padding: '8px',
    verticalAlign: 'top',

    [`&:first-of-type`]: {
      paddingLeft: '24px'
    },
    [`&:last-child`]: {
      paddingRight: '24px'
    },
    [`& svg path`]: {
      fill: 'currentColor'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`:hover`]: {
    backgroundColor: theme.palette.greys['900']
  },
  [`& td.${tableCellClasses.body}`]: {
    borderColor: theme.palette.greys['700'],
    color: theme.palette.text.primary,
    padding: '12px 8px',
    lineHeight: '24px',

    [`&:first-of-type`]: {
      paddingLeft: '24px'
    },
    [`&:last-child`]: {
      paddingRight: '24px'
    }
  }
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  width: '100%',
  display: 'block',
  color: theme.palette.primary.main,
  borderBottom: `1px solid ${theme.palette.greys[700]}`,
  padding: '12px 22px',
  ['.MuiPaginationItem-page']: {
    fontFamily: 'Aeonik Fono',
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.14px',
    color: theme.palette.text.primary,
    justifyContent: 'center',
    padding: '4px',
    margin: 0,
    textAlign: 'center',
    background: 'transparent',
    borderColor: theme.palette.greys['700'],

    '&:hover': {
      backgroundColor: theme.palette.greys['800']
    },

    '&:active': {
      backgroundColor: theme.palette.greys['700']
    },

    ['&.Mui-selected']: {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),

      '&:hover': {
        background: theme.palette.accent.hover,
        borderColor: theme.palette.accent.hover
      },

      '&:active': {
        background: theme.palette.accent.active,
        borderColor: theme.palette.accent.active
      }
    }
  },
  ['.MuiPaginationItem-previousNext']: {
    margin: 0,
    padding: 0,
    color: theme.palette.text.primary
  },
  ['.MuiPagination-ul']: {
    ['li']: {
      padding: '0 2px'
    }
  },
  ['.MuiPaginationItem-ellipsis']: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '32px',
    letterSpacing: '0.14px',
    width: 32,
    margin: 0,
    padding: 0
  },
  ['.MuiTouchRipple-root']: {
    display: 'none'
  }
}));

const DEFAULT_ROWS = 8;

const Table = <TRow, TColumn extends Column<TRow> = Column<TRow>>({
  rows,
  columns,
  rowKey,
  defaultSortOrder = 'desc',
  defaultSortBy,
  rowContent,
  onRowClick,
  title,
  rowsPerPage = DEFAULT_ROWS,
  loading
}: TableProps<TRow, TColumn>) => {
  const [order, setOrder] = useState<Order>(defaultSortOrder);
  const [sortBy, setSortBy] = useState<keyof TRow | undefined>(defaultSortBy);
  const [page, setPage] = useState(1);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (property: keyof TRow) => {
    const isAsc = sortBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const data = useMemo(() => {
    const result = [...rows];
    if (sortBy) {
      result.sort(getComparator(order, sortBy));
    }
    return result.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
  }, [order, sortBy, page, rowsPerPage, rows, defaultSortOrder, defaultSortBy]);

  const renderRow = useCallback(
    (row: TRow, id: number) => {
      return (
        <StyledTableRow
          key={`${row[rowKey]}_${id}`}
          tabIndex={-1}
          onClick={onRowClick && (() => onRowClick(row))}
          sx={onRowClick && { cursor: 'pointer' }}>
          {rowContent(row, id)}
        </StyledTableRow>
      );
    },
    [rowContent, rowKey, onRowClick]
  );

  return (
    <Box borderTop="1px solid" borderColor="greys.700">
      <Box
        display="flex"
        sx={{
          bgcolor: 'greys.900',
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
          justifyContent: {
            md: 'space-between'
          },
          alignItems: {
            xs: 'stretch',
            md: 'center'
          },
          pt: {
            xs: 2,
            md: 1
          },
          pb: 1
        }}
        px={3}>
        <Typography variant="h3">{title}</Typography>
        <Box
          display="flex"
          columnGap={2}
          sx={{
            padding: {
              md: '4px 0'
            },
            marginTop: {
              xs: 2,
              md: 0
            }
          }}>
          {/* TODO: Revert once these buttons are functional */}
          {/* <Button variant="outlined" startIcon={<Icon icon="filter" />} fullWidth>
            Filter
          </Button>
          <Button variant="outlined" fullWidth>
            Export CSV
          </Button> */}
        </Box>
      </Box>
      <Box sx={{ overflowX: 'auto' }}>
        <TableContainer>
          <MUITable>
            <TableHead>
              <StyledHeadRow>
                {columns.map((headCell, idx) => (
                  <TableCell
                    key={headCell.sortBy?.toString() || `cell-${idx}`}
                    align="left"
                    width={headCell.width}
                    sortDirection={sortBy === headCell.sortBy ? order : false}
                    onClick={() =>
                      headCell.sortBy ? handleRequestSort(headCell.sortBy) : undefined
                    }>
                    <Typography
                      variant="label"
                      display="inline-flex"
                      style={{ cursor: 'pointer', verticalAlign: 'top' }}>
                      {headCell.label}
                      {headCell.sortBy ? (
                        <Box display="flex" flexShrink={0}>
                          <Icon icon={sortBy === headCell.sortBy ? `sort-${order}` : 'sort-none'} />
                        </Box>
                      ) : null}
                    </Typography>
                  </TableCell>
                ))}
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              {!loading
                ? data.map(renderRow)
                : [...Array(rowsPerPage)].map((row, rowIdx) => (
                    <StyledTableRow key={rowIdx}>
                      {columns.map((col, cellIdx) => (
                        <TableCell key={cellIdx}>
                          <Skeleton />
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        {rows.length > rowsPerPage && (
          <StyledPagination
            count={rows.length < rowsPerPage ? 1 : Math.ceil(rows.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                slots={{
                  previous: () => (
                    <Button
                      component="span"
                      variant="outlined"
                      startIcon={<Icon icon="chevron-left" />}
                      color="inherit"
                      sx={{ padding: '3px 7px 3px 0' }}>
                      Prev
                    </Button>
                  ),
                  next: () => (
                    <Button
                      component="span"
                      variant="outlined"
                      endIcon={<Icon icon="chevron-right" />}
                      color="inherit"
                      sx={{ padding: '3px 0 3px 7px' }}>
                      Next
                    </Button>
                  )
                }}
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
};

export default Table;
