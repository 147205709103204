import aave from '@assets/clients/aave.svg';
import benqi from '@assets/clients/benqi.svg';
import radiant from '@assets/clients/radiant.svg';
import venus from '@assets/clients/venus.svg';
import dydx from '@assets/clients/dydx.svg';
import seamless from '@assets/clients/seamless.svg';
import bluefin from '@assets/clients/bluefin.svg';
import zerolend from '@assets/clients/zerolend.svg';
import GMX from '@assets/clients/GMX.svg';
import gearbox from '@assets/clients/gearbox.svg';
import jupiter from '@assets/clients/jupiter.svg';
import curve from '@assets/clients/curve.svg';
import tapioca from '@assets/clients/tapioca.svg';

enum ClientCategory {
  BorrowLend = 'Borrow & Lend',
  Perpetuals = 'Perpetuals'
}

interface ClientData {
  title: string;
  icon: string;
  slug: string;
  category: ClientCategory;
}

const data: ClientData[] = [
  {
    title: 'AAVE',
    icon: aave,
    slug: 'aave',
    category: ClientCategory.BorrowLend
  },
  {
    title: 'Benqi',
    icon: benqi,
    slug: 'benqi',
    category: ClientCategory.BorrowLend
  },
  {
    title: 'Radiant',
    icon: radiant,
    slug: 'radiant',
    category: ClientCategory.BorrowLend
  },
  {
    title: 'Venus',
    icon: venus,
    slug: 'venus',
    category: ClientCategory.BorrowLend
  },
  {
    title: 'dYdX',
    icon: dydx,
    slug: 'dydx',
    category: ClientCategory.Perpetuals
  },
  {
    title: 'Seamless',
    icon: seamless,
    slug: 'seamless',
    category: ClientCategory.BorrowLend
  },
  {
    title: 'Bluefin',
    icon: bluefin,
    slug: 'bluefin',
    category: ClientCategory.Perpetuals
  },
  {
    title: 'Zerolend',
    icon: zerolend,
    slug: 'zerolend',
    category: ClientCategory.BorrowLend
  },
  {
    title: 'GMX',
    icon: GMX,
    slug: 'gmx',
    category: ClientCategory.Perpetuals
  },
  {
    title: 'Gearbox',
    icon: gearbox,
    slug: 'gearbox',
    category: ClientCategory.BorrowLend
  },
  {
    title: 'Jupiter',
    icon: jupiter,
    slug: 'jupiter',
    category: ClientCategory.Perpetuals
  },
  {
    title: 'Curve',
    icon: curve,
    slug: 'curve',
    category: ClientCategory.BorrowLend
  },
  {
    title: 'Tapioca',
    icon: tapioca,
    slug: 'tapioca',
    category: ClientCategory.BorrowLend
  }
];

interface ClientsData {
  clients: ClientData[];
  getClientBySlug: (slug: string) => ClientData | undefined;
}

export const useClientsData = (): ClientsData => {
  return {
    clients: data,
    getClientBySlug: (slug: string) => data.find((client) => client.slug === slug)
  };
};
