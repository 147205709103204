import { Theme } from '@mui/material';

export const chartTheme = (themePalette: Theme) => ({
  animation: false,
  backgroundColor: 'transparent',
  textColor: themePalette.palette.greys['400'],
  textStyle: {
    fontFamily: 'Aeonik Fono',
    color: themePalette.palette.greys['400'],
    fontWeight: 500,
    fontSize: 14
  },
  color: [themePalette.palette.primary.main],
  borderWidth: 0,
  tooltip: {
    borderWidth: 0,
    backgroundColor: themePalette.palette.greys['700'],
    textStyle: {
      color: themePalette.palette.text.primary
    }
  },
  timeAxis: {
    nameTextStyle: {
      color: themePalette.palette.text.primary
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      fontSize: 12
    }
  },
  valueAxis: {
    nameTextStyle: {
      color: themePalette.palette.text.primary,
      fontSize: 14
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#ffffff33'],
        type: [4, 4]
      }
    },
    axisLabel: {
      fontSize: 12
    }
  },
  grid: {
    containLabel: true,
    left: 5,
    top: 10,
    right: 5,
    bottom: 5
  }
});
