import React from 'react';
import { Box, SxProps } from '@mui/material';
import { getCryptoIconSrc } from './get-crypto-icon';

enum IconSize {
  'x-small' = 16,
  'small' = 20,
  'default' = 24
}

export interface CryptoIconProps {
  icon: string;
  className?: string;
  sx?: SxProps;
  size?: 'x-small' | 'small' | 'default';
}

const CryptoIcon = React.forwardRef<unknown, CryptoIconProps>(
  ({ icon, className, sx, size = 'default', ...props }: CryptoIconProps, ref) => {
    const sizeValue = size && IconSize[size];

    return (
      <Box
        {...props}
        component="span"
        ref={ref}
        className={`${icon}-icon ${className || ''}`}
        sx={{
          display: 'inline-flex',
          verticalAlign: 'middle',
          alignItems: 'center',
          img: {
            ...(sizeValue && { width: sizeValue, height: sizeValue }),
            display: 'block'
          },
          ...sx
        }}>
        <img alt={`${icon}-icon`} src={getCryptoIconSrc(icon)} />
      </Box>
    );
  }
);

export default CryptoIcon;
